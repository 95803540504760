import React from 'react';
import WorkTitle from "../../../components/Work/WorkTitle/WorkTitle";
import WorkText from "../../../components/Work/WorkText/WorkText";
import WorkBanner from "../../../components/Work/WorkBanner/WorkBanner";

import ProstirApp1 from "./images/ProstirApp1.png";
import ProstirApp2 from "./images/ProstirApp2.png";
import ProstirApp3 from "./images/ProstirApp3.png";
import ProstirApp4 from "./images/ProstirApp4.png";
import ProstirApp5 from "./images/ProstirApp5.png";
import ProstirApp6 from "./images/ProstirApp6.png";
import ProstirApp7 from "./images/ProstirApp7.svg";

import WorkScreenshots from "../../../components/Work/WorkScreenshots/WorkScreenshots";
// import WorkFigma from "../../../components/Work/WorkFigma/WorkFigma";

function ProstirApp() {
    return (
        <section className="container container-work">
            <WorkTitle>
                Co-founder & Product Designer: iOS app from concept to launch 🤳 📝
            </WorkTitle>

            <WorkText>
                Duration: [04.2023 — Present]
            </WorkText>

            <WorkText>
                <strong>Prostir</strong> is a mobile app that helps users plan their tasks, build good habits, and take notes. It includes features like daily tasks, habit tracking, reminders, and progress analysis.
            </WorkText>

            <WorkText>
                <a className="Works__link" href="https://apps.apple.com/pl/app/daily-planner-prostir/id6535696029" target="_blank">
                    Download the app on iOS.
                </a>
            </WorkText>

            <WorkBanner images={[ProstirApp1, ProstirApp2, ProstirApp3]}/>

            <WorkText title="🛠 My Role & Responsibilities">
                <p>
                    <strong>Research:</strong> Conducted detailed user interviews and competitor analysis to identify the target audience's pain points and define key features.
                </p>
                <p>
                    <strong>UI/UX Design:</strong> Designed user flows, wireframes, and interactive prototypes to ensure the app was intuitive and aesthetically pleasing.
                </p>
                <p>
                    <strong>Brand Identity:</strong> Created a cohesive visual identity, including the logo and design system, to establish a recognizable and modern brand.
                </p>
                <p>
                    <strong>Prototyping & Testing:</strong> Developed high-fidelity prototypes and conducted iterative user testing to refine usability and meet user expectations.
                </p>
                <p>
                    <strong>Product Launch:</strong> Contributed to marketing strategies and App Store optimization to drive engagement and downloads.
                </p>
            </WorkText>
            <WorkScreenshots color="gray-light"
                             images={[ProstirApp2, ProstirApp3, ProstirApp1]}/>

            <WorkText title="🛠 Results">
                <ul>
                    <li>
                        Achieved <strong>970 downloads</strong> in the first two months post-launch.
                    </li>
                    <li>
                        Maintained a <strong>12.5% conversion rate</strong> from product page views to downloads.
                    </li>
                    <li>
                        Generated <strong>11.9K impressions</strong> and <strong>3.87K product page views</strong> in the App Store.
                    </li>
                    <li>
                        Users recorded an average of <strong>2.46 sessions</strong> per active device daily.
                    </li>
                </ul>
            </WorkText>
            <WorkScreenshots color="gray-light"
                             images={[ProstirApp4, ProstirApp5, ProstirApp6]}/>

            <WorkBanner images={[ProstirApp7]} isFullBG/>
            {/*<WorkFigma src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FQqbv4NKh0SVGZazFJrgIpq%2FBalance-Mobile-App%3Ftype%3Ddesign%26node-id%3D847%253A17211%26mode%3Ddesign%26t%3D3tVqtsvGVWTrAnVD-1"/>*/}
        </section>
    );
}

export default ProstirApp;
