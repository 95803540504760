import React from 'react';
import './WorkText.scss';
import classNames from "classnames";

type WorkTextProps = {
    children: React.ReactNode;
    title?: string;
    hasSeparator?: boolean;
    isNewVersion?: boolean;
};

const WorkText: React.FC<WorkTextProps> = ({children, title, hasSeparator, isNewVersion}) => {
    return (
        <div className={classNames("WorkText", {"WorkText--hasSeparator": hasSeparator}, {"WorkText--isNewVersion": isNewVersion})}>
            {title && (
                <h2 className="WorkText__title">{title}</h2>
            )}
            <div>
                {children}
            </div>
        </div>
    );
}

export default WorkText;
