import React from 'react';
import Picture from "../../Picture/Picture";
import './WorkBanner.scss';
import classNames from "classnames";
import WorkSlider from "../WorkSlider/WorkSlider";
import useIsMobile from "../../../hooks/useIsMobile";

type WorkBannerProps = {
    images: string[];
    autoHeight?: boolean;
    isWithoutBG?: boolean;
    isFullBG?: boolean;
};

const WorkBanner: React.FC<WorkBannerProps> = ({images, autoHeight, isWithoutBG, isFullBG}) => {
    const isMobile = useIsMobile();

    if (images.length >= 2 && isMobile) {
        return <WorkSlider images={images}/>
    }

    return (
        <div className={classNames('WorkBanner', {autoHeight: autoHeight || images.length === 1, isWithoutBG, isFullBG})}>
            {images.length === 1 && (
                <Picture className="WorkBanner__img" imgSrc={images[0]}/>
            )}

            {images.length >= 2 && (
                <>
                    {images.map((img, index) => (
                        <Picture key={index} className="WorkBanner__picture" imgSrc={img}/>
                    ))}
                </>
            )}
        </div>
    );
}

export default WorkBanner;
