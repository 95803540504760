import React from 'react';
import Tile from "../../components/Tile/Tile";
import Picture from "../../components/Picture/Picture";
import AIMentalHealthAppImage from "./AIMentalHealthApp/images/AIMentalHealthApp-4.png";
import MobileAppForMeditations from "./MobileAppForMeditations/images/MobileAppForMeditations.png";
import AppForControllingLightsImage from "./AppForControllingLights/images/AppForControllingLights-5.png";
import OnlineStoreOfBrandedClothesBanner2Image from "./OnlineStoreOfBrandedClothes/images/OnlineStoreOfBrandedClothesBanner2.png";
import CRMSystemForSalesManagementImage from "./CRMSystemForSalesManagement/images/CRMSystemForSalesManagementItem.png";
import FitnessTrainerSearchWebsite from "./FitnessTrainerSearchWebsite/images/FitnessTrainerSearchWebsiteItem.png";
import CorporateBankingSystemImage from "./CorporateBankingSystem/images/CorporateBankingSystemItem.png";
import LMSForAfghanGirlsImage from "./LMSForAfghanGirls/images/LMSForAfghanGirlsItem.png";
import ProstirAppImage from "./ProstirApp/images/ProstirApp.png";
import ContentPlanningAppImage from "./ContentPlanningApp/images/ContentPlanningApp.png";
import './Works.scss';

const grid = [
    {
        works: [
            {
                title: 'Co-founder & Product Designer: iOS app from concept to launch 🤳',
                img: ProstirAppImage,
                path: 'prostir-app'
            },
            {
                title: 'Product Designer:  iOS App for Social Media Content Planning from concept to launch 📝',
                img: ContentPlanningAppImage,
                path: 'content-planning-app'
            }
        ]
    },
    {
        works: [
            {
                title: 'AI Mental Health App',
                img: AIMentalHealthAppImage,
                path: 'ai-mental-health-app'
            },
            {
                title: 'Mobile App for Meditations',
                img: MobileAppForMeditations,
                path: 'mobile-app-for-meditations'
            }
        ]
    },
    {
        works: [
            {
                title: 'App for controlling lights',
                img: AppForControllingLightsImage,
                path: 'application-for-remote-light-control'
            },
            {
                title: 'Online store of branded clothes',
                img: OnlineStoreOfBrandedClothesBanner2Image,
                path: 'online-store-of-branded-clothes'
            }
        ]
    },
    {
        works: [
            {
                title: 'Corporate banking system',
                img: CorporateBankingSystemImage,
                path: 'corporate-banking-system'
            },
            {
                title: 'LMS for Afghan girls',
                img: LMSForAfghanGirlsImage,
                path: 'lms-for-afghan-girls'
            }
        ]
    },
    {
        works: [
            {
                title: 'CRM System',
                img: CRMSystemForSalesManagementImage,
                path: 'crm-system-for-sales-management'
            },
            {
                title: 'Fitness Trainer Search Website',
                img: FitnessTrainerSearchWebsite,
                path: 'fitness-trainer-search-website'
            }
        ]
    }
]

function Works() {
    return (
        <section className="Works">
            <div className="container">
                <div className="grid gap-6">
                    {grid.map(item => (
                        <div className={`grid gap-6 grid-cols-1 lg:grid-cols-${item.works.length}`}>
                            {item.works.map(work => (
                                <Tile className="Works__tile" title={work.title} to={work.path}>
                                    <Picture title={work.title}
                                             imgSrc={work.img}/>
                                </Tile>
                            ))}
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default Works;
