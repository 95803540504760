import React from 'react';
import './PhotoGallery.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import {Autoplay, EffectFade} from "swiper/modules";
import 'swiper/css';
import 'swiper/css/effect-fade';
import Picture from "../Picture/Picture";
import Photo1 from "./Photos/photo1.jpg";
import Photo2 from "./Photos/photo2.jpg";
import Photo3 from "./Photos/photo3.jpg";
import Photo4 from "./Photos/photo4.jpg";
import Photo5 from "./Photos/photo5.jpg";
import Photo6 from "./Photos/photo6.jpg";
import Photo7 from "./Photos/photo7.jpg";
import Photo8 from "./Photos/photo8.jpg";
import Photo9 from "./Photos/photo9.jpg";

function PhotoGallery() {
    return (
        <Swiper
            modules={[Autoplay, EffectFade]}
            slidesPerView={1}
            loop={true}
            effect="fade"
            autoplay={{delay: 2500}}
        >
            <SwiperSlide>
                <Picture className="PhotoGallery__picture" title="Photo 1" imgSrc={Photo1}/>
            </SwiperSlide>
            <SwiperSlide>
                <Picture className="PhotoGallery__picture" title="Photo 2" imgSrc={Photo2}/>
            </SwiperSlide>
            <SwiperSlide>
                <Picture className="PhotoGallery__picture" title="Photo 3" imgSrc={Photo3}/>
            </SwiperSlide>
            <SwiperSlide>
                <Picture className="PhotoGallery__picture" title="Photo 4" imgSrc={Photo4}/>
            </SwiperSlide>
            <SwiperSlide>
                <Picture className="PhotoGallery__picture" title="Photo 5" imgSrc={Photo5}/>
            </SwiperSlide>
            <SwiperSlide>
                <Picture className="PhotoGallery__picture" title="Photo 6" imgSrc={Photo6}/>
            </SwiperSlide>
            <SwiperSlide>
                <Picture className="PhotoGallery__picture" title="Photo 7" imgSrc={Photo7}/>
            </SwiperSlide>
            <SwiperSlide>
                <Picture className="PhotoGallery__picture" title="Photo 8" imgSrc={Photo8}/>
            </SwiperSlide>
            <SwiperSlide>
                <Picture className="PhotoGallery__picture" title="Photo 9" imgSrc={Photo9}/>
            </SwiperSlide>
        </Swiper>
    );
}

export default PhotoGallery;
