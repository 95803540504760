import React, {useEffect} from 'react';
import {Routes, Route, useLocation} from "react-router-dom";

import Header from "./components/Header/Header";
import Home from "./pages/Home/Home";
import About from "./pages/About/About";
import Works from "./pages/Works/Works";
import AIMentalHealthApp from "./pages/Works/AIMentalHealthApp";
import MobileAppForMeditations from "./pages/Works/MobileAppForMeditations";
import AppForControllingLights from "./pages/Works/AppForControllingLights";
import OnlineStoreOfBrandedClothes from "./pages/Works/OnlineStoreOfBrandedClothes";
import CRMSystemForSalesManagement from "./pages/Works/CRMSystemForSalesManagement";
import CorporateBankingSystem from "./pages/Works/CorporateBankingSystem";
import LMSForAfghanGirls from "./pages/Works/LMSForAfghanGirls";
import FitnessTrainerSearchWebsite from "./pages/Works/FitnessTrainerSearchWebsite";
import ProstirApp from "./pages/Works/ProstirApp";
import ContentPlanningApp from "./pages/Works/ContentPlanningApp";

function App() {
    const {pathname} = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <div className="App">
            <Header/>

            <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path="/about" element={<About/>}/>
                <Route path="/works" element={<Works/>}/>
                <Route path="/works/ai-mental-health-app" element={<AIMentalHealthApp/>}/>
                <Route path="/works/mobile-app-for-meditations" element={<MobileAppForMeditations/>}/>
                <Route path="/works/application-for-remote-light-control" element={<AppForControllingLights/>}/>
                <Route path="/works/online-store-of-branded-clothes" element={<OnlineStoreOfBrandedClothes/>}/>
                <Route path="/works/crm-system-for-sales-management" element={<CRMSystemForSalesManagement/>}/>
                <Route path="/works/corporate-banking-system" element={<CorporateBankingSystem/>}/>
                <Route path="/works/lms-for-afghan-girls" element={<LMSForAfghanGirls/>}/>
                <Route path="/works/fitness-trainer-search-website" element={<FitnessTrainerSearchWebsite/>}/>
                <Route path="/works/prostir-app" element={<ProstirApp/>}/>
                <Route path="/works/content-planning-app" element={<ContentPlanningApp/>}/>

                <Route path="*" element={<Home/>}/>
                {/*<Route path="*" element={<NotFound />} />*/}
            </Routes>
        </div>
    );
}

export default App;
