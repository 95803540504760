import React from 'react';
import WorkTitle from "../../../components/Work/WorkTitle/WorkTitle";
import WorkText from "../../../components/Work/WorkText/WorkText";
import WorkBanner from "../../../components/Work/WorkBanner/WorkBanner";

import WorkScreenshots from "../../../components/Work/WorkScreenshots/WorkScreenshots";
// import WorkFigma from "../../../components/Work/WorkFigma/WorkFigma";

import ContentPlanningApp1 from "./images/ContentPlanningApp1.png";
import ContentPlanningApp2 from "./images/ContentPlanningApp2.png";
import ContentPlanningApp3 from "./images/ContentPlanningApp3.png";
import ContentPlanningApp4 from "./images/ContentPlanningApp4.png";
import ContentPlanningApp5 from "./images/ContentPlanningApp5.png";
import ContentPlanningApp6 from "./images/ContentPlanningApp6.png";
import ContentPlanningApp7 from "./images/ContentPlanningApp7.png";

function ContentPlanningApp() {
    return (
        <section className="container container-work">
            <WorkTitle>
                Product Designer: iOS App for Social Media Content Planning from concept to launch 🤳 📝
            </WorkTitle>

            <WorkText>
                Duration: [11.2023 — Present]
            </WorkText>

            <WorkText>
                <strong>TURN</strong> is an iOS app designed to help users plan and organize their social media content grid. The app allows users to visually manage their posts, ensuring their social media profiles remain consistent and aesthetically pleasing.
            </WorkText>

            <WorkText>
                <p className="Works__link Works__link--disabled">Download the app on iOS. <strong>Soon.</strong></p>
            </WorkText>

            <WorkBanner images={[ContentPlanningApp1, ContentPlanningApp2, ContentPlanningApp3]}/>

            <WorkText title="🛠 My Role & Responsibilities">
                <p>
                    <strong>Research:</strong> Conducted in-depth research to understand user needs for social media content planning, focusing on the visual aspects of the social media grid.
                </p>
                <p>
                    <strong>UI/UX Design:</strong> Developed user flows, wireframes, and high-fidelity UI/UX designs to create an intuitive, user-friendly experience.
                </p>
                <p>
                    <strong>Brand Identity:</strong> Designed the app’s mascot, logo, and overall visual identity, ensuring cohesive branding throughout.
                </p>
                <p>
                    <strong>Prototyping & Testing:</strong> Created interactive prototypes for user testing and iteration, refining the user experience based on feedback.
                </p>
            </WorkText>
            <WorkScreenshots color="gray-light"
                             images={[ContentPlanningApp3, ContentPlanningApp2, ContentPlanningApp4]}/>

            <WorkText title="🛠 Results">
                <p>
                    Results and performance metrics will be updated following the app's release and user feedback collection.
                </p>
            </WorkText>
            <WorkScreenshots color="gray-light"
                             images={[ContentPlanningApp6, ContentPlanningApp1, ContentPlanningApp5]}/>

            <WorkBanner images={[ContentPlanningApp7]} isFullBG/>
            {/*<WorkFigma src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FQqbv4NKh0SVGZazFJrgIpq%2FBalance-Mobile-App%3Ftype%3Ddesign%26node-id%3D847%253A17211%26mode%3Ddesign%26t%3D3tVqtsvGVWTrAnVD-1"/>*/}
        </section>
    );
}

export default ContentPlanningApp;
