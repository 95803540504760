import React from 'react';
import Tile from "../../components/Tile/Tile";
import TileFooter from "../../components/Tile/Footer/TileFooter";
import Picture from "../../components/Picture/Picture";
import AIMentalHealthAppImage from "../Works/AIMentalHealthApp/images/AIMentalHealthApp-4.png";
import ProstirAppImage from "../Works/ProstirApp/images/ProstirApp.png";
import ContentPlanningAppImage from "../Works/ContentPlanningApp/images/ContentPlanningApp.png";

import './Home.scss';

function Home() {
    const handleLinkClick = (event: any, href: string) => {
        event?.preventDefault();
        window.open(href, '_blank');
    }

    return (
        <section className="Home">
            <div className="container">
                <div className="grid gap-6">
                    <div className="grid gap-6 grid-cols-1 lg:grid-cols-2">
                        <Tile title="Co-founder & Product Designer: iOS app from concept to launch 🤳"
                              to="works/prostir-app">
                            <Picture className="Home__picture" title="Prostir app" imgSrc={ProstirAppImage}/>
                        </Tile>
                        <Tile
                            title="Product Designer:  iOS App for Social Media Content Planning from concept to launch 📝"
                            to="works/content-planning-app">
                            <Picture className="Home__picture" title="Content planning app"
                                     imgSrc={ContentPlanningAppImage}/>
                        </Tile>
                    </div>
                    <div className="grid gap-6 grid-cols-1 lg:grid-cols-2">
                        <Tile title="About Me" to="about" footer={<TileFooter/>}>
                            <p>
                                🎨 I create interfaces and identity, work with analytics, communicate with users, and
                                look for effective and creative solutions.
                            </p>
                            <p>
                                📱 Currently, I am working on the design and development of the new iOS app{' '}
                                <a
                                    onClick={(event) => handleLinkClick(event, 'https://apps.apple.com/pl/app/daily-planner-prostir/id6535696029')}
                                    className="Home__link"
                                    rel="noreferrer"
                                    href="https://apps.apple.com/pl/app/daily-planner-prostir/id6535696029"
                                    target="_blank">Prostir</a>.
                                Before that, I worked at the outsourcing company{' '}
                                <a className="Home__link"
                                   onClick={(event) => handleLinkClick(event, 'https://it-devgroup.us')}
                                   href="https://it-devgroup.us"
                                   rel="noreferrer"
                                   target="_blank">IT Dev Group</a>, where I was involved in
                                creating and improving apps and web platforms across various domains.
                            </p>
                            <p>
                                💡 I can help with: Identifying issues in the funnel → Gathering the necessary
                                quantitative and qualitative data → Testing and validating hypotheses → Providing
                                effective solutions.
                            </p>
                        </Tile>
                        <Tile title="AI Mental Health App" to="works/ai-mental-health-app">
                            <Picture className="Home__picture" title="AI Mental Health App"
                                     imgSrc={AIMentalHealthAppImage}/>
                        </Tile>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Home;
