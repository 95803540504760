import React from 'react';
import Tile from "../../components/Tile/Tile";
import './About.scss';
import PhotoGallery from "../../components/PhotoGallery/PhotoGallery";
import Tag from '../../components/Tag/Tag';
import InstagramTile from "../../components/Tile/InstagramTile/InstagramTile";
import LinkedinTile from "../../components/Tile/LinkedinTile/LinkedinTile";

function About() {
    return (
        <section className="About">
            <div className="container">
                <div className="grid gap-6">
                    <div className="grid gap-6 grid-cols-1 lg:grid-cols-2">
                        <div className="grid gap-6">
                            <Tile title="Feel free to reach out to me through whichever method you prefer" isTitleSmall>
                                <div className="About__tags">
                                    <Tag to="https://t.me/nusevich" icons={["telegram"]}>@nusevich</Tag>
                                    <Tag to="mailto:nataliusevich2.0@gmail.com"
                                         icons={["google"]}>nataliusevich2.0@gmail.com</Tag>
                                    <Tag to="tel:+48571447074" icons={["whatsapp", "messenger"]}>+48 571 447 074</Tag>
                                </div>
                            </Tile>

                            <Tile>
                                <h3>My approach to design</h3>
                                <p>
                                    My experience allows me to approach design as a strategy, where every decision
                                    serves a clear purpose. I believe that <strong>effective design is the result of a
                                    deep understanding of the context, user needs, and the data</strong> at hand. I
                                    constantly test hypotheses to ensure that each product performs at its best. My goal
                                    is to create solutions that optimize interactions, enhance efficiency, and
                                    ultimately help achieve results.
                                </p>

                                <h3>How I started my journey in UX design</h3>
                                <p>
                                    After graduating from college, I started my own project translating subtitles for TV
                                    series and movies. Eventually, I built a team of 60 translators and managed this
                                    project for 7 years. I became interested in UX design when it was time to develop my
                                    own website.
                                </p>
                                <p>
                                    Later on, I left my project (for countless reasons) and began my path in UX design.
                                    The website for this project became my first design project.
                                </p>
                                <h3>Where I'm from and where I studied</h3>
                                <p>
                                    I was born and raised in Ukraine. I've always been drawn to modern technologies, and
                                    despite financial difficulties, I managed to get into the College of the Kyiv
                                    Aviation University, where I earned a diploma in Desktop Publishing Systems.
                                </p>
                                <p>
                                    I remember how my classmates and I designed children's coloring books and delivered
                                    printed copies to orphanages. That was my first experience with design for people.
                                </p>
                            </Tile>
                        </div>
                        <div>
                            <div className="grid gap-6 grid-cols-1">
                                <Tile title="My life in the photo gallery" isTitleSmall>
                                <PhotoGallery/>
                                </Tile>

                                <Tile>
                                    <h3>Here's how I'm rolling these days</h3>

                                    <p>
                                        I created a community for Ukrainian women in Katowice and help them settle in
                                        after moving due to the war - instagram.com/katowice.ukraine 🇺🇦💬
                                    </p>
                                    <p>
                                        I paint and host art meetings for others - instagram.com/artclub.katowice 🎨🖌️
                                    </p>
                                    <p>
                                        I read books, write books, and work on my writing skills 📚✍️
                                    </p>
                                    <p>
                                        Whenever I get the chance and time, I travel the world and immerse myself in its
                                        beauty. My favorite place on earth is Crete 🌍✈️🌞
                                    </p>
                                    <p>
                                        I play badminton and tennis, ride my bike, do pilates, and run 🏸🎾🚴‍♀️💪
                                    </p>
                                </Tile>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default About;
